export const ACCESS_TOKEN_SESSION_STORAGE_KEY_NAME = 'access_token';
export const NUM_REPOS_PER_PAGE = 10;
export const NUM_NODES_PER_PAGE = 10;
export const NODE_TITLE_MAX_CHARS = 15;
export const DEPENDENCY_CONTEXT_REFRESH_ACTION_NAME = 'REFRESH';
export const LINEAGE_YAML_FILE_NAME = 'lineage.yaml';
export const PACKAGE_JSON_FILE_NAME = 'package.json';
export const REPO_FULL_NAME_PREFIX = 'github.com/';

export const HTTP_FORBIDDEN_RESPONSE_STATUS_CODE = 403;
export const HTTP_UNAUTHORIZED_RESPONSE_STATUS_CODE = 401;
export const HTTP_NOT_FOUND_RESPONSE_STATUS_CODE = 404;
export const HTTP_OK_RESPONSE_STATUS_CODE = 200;
export const HTTP_BAD_REQUEST_STATUS_CODE = 400;